
#preloader {
  background-color: #f1f2fb;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  top: 0;
  left: 0;
  overflow: hidden; }

a {
  color: #000; }
 
.codekali-card{
  height: 250px!important;
}
caption {
  color: #000; }
 

/* :: Reboot CSS */
* {
  margin: 0;
  padding: 0; }

body,
html {
  font-family: "Montserrat", sans-serif;
  overflow-x: hidden;
  background-color: #fff;
  color: #000;
  scrollbar-width: thin; 

}


h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.3;
  color: #000; }

p {
  font-size: 14px;
  color: #000; }

a,
a:hover,
a:focus {
  -webkit-transition-duration: 500ms;
          transition-duration: 500ms;
  text-decoration: none;
  outline: 0 solid transparent; }
button{
  cursor: pointer!important;
}
ul {
  margin: 0; }
  ul li {
    list-style: none;
    text-decoration: none; }
    ul li:hover, ul li:focus {
      list-style: none;
      text-decoration: none; }

img {
  max-width: 100%;
  height: auto; }

 a{
  cursor: pointer!important;
 }


/* :: Miscellaneous CSS */
.app-wrapper {
  position: relative;
  z-index: 1;
/*  margin-top: 70px;*/
/*  margin-bottom: 62px; */

}


/* :: Internet Status CSS */
.internet-connection-status {
  position: fixed;
  background-color: transparent;
  width: 100%;
  height: 40px;
  z-index: 99999;
  text-align: center;
  color: #ffffff;
  bottom: 62px;
  left: 0;
  right: 0;
  line-height: 40px;
  font-weight: 700;
  font-size: 14px; }
  .internet-connection-status.internet-is-back {
    background-color: #2ecc4a; }
  .internet-connection-status.internet-is-lost {
    background-color: #ea4c62; }


/* :: Footer Nav CSS */
.footer-nav-area {
  position: fixed !important;
  -webkit-transition-duration: 500ms;
          transition-duration: 500ms;
  background-color: #ffffff;
  width: 100%;
  height: 62px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  border-top: 1px solid #ebebeb; }

.footer-nav {
  background-color: #ffffff;
  width: 100%;
  height: 62px; }
  .footer-nav ul {
    position: relative;
    z-index: 10;
    width: 100%; }


    .codekali-footer-nav ul li a {
    align-items: center;
    color: #28363e;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    justify-content: center;
    line-height: 16px;
    position: relative;
}
    .codekali-footer-nav ul li {
    text-align: center;
      position: relative;
      z-index: 1;
      -webkit-box-flex: 1;
          -ms-flex: 1 1 0px;
              flex: 1 1 0; }


      .footer-nav ul li a {
        position: relative;
        display: block;
        font-size: 10px;
        text-align: center;
        font-weight: 500;
        text-transform: uppercase;
        line-height: 1;
        color: #000;
        z-index: 1; }
         .footer-nav ul li a svg {
        display: block;
        font-size: 20px;
        margin-bottom: 4px; }
        .footer-nav ul li a svg {
          -webkit-transition-duration: 500ms;
                  transition-duration: 500ms; }
        .codekali-footer-nav ul li a span {
          font-size: 10px;
          -webkit-transition-duration: 500ms;
                  transition-duration: 500ms;
          color: #000;
          display: block;
          margin-top: .25rem; }
        .footer-nav ul li a:hover, .footer-nav ul li a:focus {
          color: #232F3E; }
          .footer-nav ul li a:hover span, .footer-nav ul li a:focus span {
            color: #232F3E; }
      .footer-nav ul li.active a {
        color: #232F3E; }
        .footer-nav ul li.active a span {
          color: #232F3E; }
  .footer-nav.footer-style-two li.active a {
    position: relative;
    z-index: 1;
    width: 3.5rem;
    height: 3.5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    background-color: rgba(1, 52, 212, 0.3);
    margin: -25px auto 0;
    border-radius: 50%;
    color: #ffffff;
    -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }
    .footer-nav.footer-style-two li.active a::before {
      border-radius: 50%;
      position: absolute;
      width: 80%;
      height: 80%;
      top: 10%;
      right: 10%;
      z-index: -10;
      background-color: #232F3E;
      content: ""; }
  .footer-nav.footer-style-three ul li a {
    background-color: #f1f2fb;
    width: 2.625rem;
    height: 2.625rem;
    border-radius: 50%;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .footer-nav.footer-style-three ul li a:hover, .footer-nav.footer-style-three ul li a:focus {
      background-color: #232F3E;
      color: #ffffff; }
  .footer-nav.footer-style-three ul li.active a {
    background-color: #232F3E;
    color: #ffffff; }
  .footer-nav.footer-style-four ul li {
    text-align: center; }
    .footer-nav.footer-style-four ul li a {
      color: #000; }
      .footer-nav.footer-style-four ul li a:hover, .footer-nav.footer-style-four ul li a:focus {
        color: #232F3E; }
    .footer-nav.footer-style-four ul li span {
      font-size: 12px;
      display: block;
      margin-top: 0.25rem;
      font-weight: 500;
      line-height: 1; }
    .footer-nav.footer-style-four ul li.active a {
      color: #232F3E; }
  .footer-nav.footer-style-five ul li {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .footer-nav.footer-style-five ul li::after {
      width: 2rem;
      height: 3px;
      background-color: #232F3E;
      content: "";
      position: absolute;
      bottom: 0;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
      left: 50%;
      opacity: 0;
      -webkit-transition-duration: 500ms;
              transition-duration: 500ms; }
    .footer-nav.footer-style-five ul li.active::after, .footer-nav.footer-style-five ul li:hover::after, .footer-nav.footer-style-five ul li:focus::after {
      opacity: 1; }
  .footer-nav.footer-style-six ul li {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .footer-nav.footer-style-six ul li::after {
      width: 2rem;
      height: 3px;
      background-color: #232F3E;
      content: "";
      position: absolute;
      top: 0;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
      left: 50%;
      opacity: 0;
      -webkit-transition-duration: 500ms;
              transition-duration: 500ms; }
    .footer-nav.footer-style-six ul li.active::after, .footer-nav.footer-style-six ul li:hover::after, .footer-nav.footer-style-six ul li:focus::after {
      opacity: 1; }
  .footer-nav.footer-style-seven ul li {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .footer-nav.footer-style-seven ul li::after {
      width: 7px;
      height: 7px;
      background-color: #2ecc4a;
      border-radius: 50%;
      content: "";
      position: absolute;
      bottom: 5px;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
      left: 50%;
      opacity: 0;
      -webkit-transition-duration: 500ms;
              transition-duration: 500ms; }
    .footer-nav.footer-style-seven ul li.active::after, .footer-nav.footer-style-seven ul li:hover::after, .footer-nav.footer-style-seven ul li:focus::after {
      opacity: 1; }


 /*carousel icons*/

 .carousel-indicators [data-bs-target] {
background-color: #f1b10f !important;
 }

 .carousel-indicators button
{
  width : 16px!important;
  height: 16px !important;
  border-radius: 100%;
  margin: 5px !important;
  border: 2px solid #fff!important;
}

.carousel-control-next-icon {
/*  background: none;*/
  font-size: 20px;
  line-height: 0;
  width: auto;
  height: auto;
  background: #0A8AB0;
  border-radius: 50px;
  transition: 0.3s;
  color: #fff;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>");
}


 .carousel-control-prev-icon {
/*  background: none;*/
  font-size: 20px;
  line-height: 0;
  width: auto;
  height: auto;
  background: #0A8AB0;
  border-radius: 50px;
  transition: 0.3s;
  color: #fff;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>");
}

.carousel-control-next-icon:hover, .carousel-control-next-icon:focus, .carousel-control-next-icon:active {
  background: #E20591;
  color: rgba(255, 255, 255, 0.8);
  background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>");
}

.carousel-control-prev-icon:hover,.carousel-control-prev-icon:focus,.carousel-control-prev-icon:active {
  background: #E20591;
  color: rgba(255, 255, 255, 0.8);
  background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>");
}

 

   @media screen and (max-width: 768px){
  
   /*carousel icons*/

.carousel-control-next-icon {
/*  background: none;*/
  font-size: 12px;
  line-height: 0;
  width: auto;
  height: auto;
  background: #0A8AB0;
  border-radius: 50px;
  transition: 0.3s;
  color: #fff;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>");
}


 .carousel-control-prev-icon {
/*  background: none;*/
  font-size: 12px;
  line-height: 0;
  width: auto;
  height: auto;
  background: #0A8AB0;
  border-radius: 50px;
  transition: 0.3s;
  color: #fff;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>");
}

.carousel-control-next-icon:hover, .carousel-control-next-icon:focus, .carousel-control-next-icon:active {
  background: #E20591;
  color: rgba(255, 255, 255, 0.8);
  background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>");
}

.carousel-control-prev-icon:hover,.carousel-control-prev-icon:focus,.carousel-control-prev-icon:active {
  background: #E20591;
  color: rgba(255, 255, 255, 0.8);
  background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>");
}
       }

.carousel-indicators li {
  width: 10px;
  height: 10px;
  border-radius: 50%; }
  .carousel-indicators li.active {
    background-color: #f1b10f; }
  

/* :: Dark CSS */
[data-theme="dark"] {
  background-color: #0C153B; }
  [data-theme="dark"] body {
    background-color: #0C153B; }
    [data-theme="dark"] body h1,
    [data-theme="dark"] body h2,
    [data-theme="dark"] body h3,
    [data-theme="dark"] body h4,
    [data-theme="dark"] body h5,
    [data-theme="dark"] body h6 {
      color: #ffffff; }
    [data-theme="dark"] body #preloader {
      background-color: #0C153B; }
    [data-theme="dark"] body .preview-iframe-wrapper .preview-hero-area .version-number {
      background-color: #242644; }
    [data-theme="dark"] body .preview-iframe-wrapper .features-area {
      background-color: #242644; }
    [data-theme="dark"] body .preview-iframe-wrapper .features-area .card.active {
      border: 1px solid transparent !important;
      background-color: rgba(255, 255, 255, 0.05) !important; }
    [data-theme="dark"] body .preview-iframe-wrapper .features-area .card.active h6 {
      color: #ffffff; }
    [data-theme="dark"] body .card {
      background-color: #242644;
      -webkit-box-shadow: none;
              box-shadow: none; }

    [data-theme="dark"] body .page--item {
      color: #ffffff; }

    [data-theme="dark"] body .header-area {
      background-color: #242644;
      border-bottom-color: rgba(255, 255, 255, 0.1); }
    [data-theme="dark"] body .header-content .back-button a {
      color: #f1b10f; }
    [data-theme="dark"] body .sidenav-wrapper {
      background-color: #242644; }
    [data-theme="dark"] body .sidenav-nav li:hover,
    [data-theme="dark"] body .sidenav-nav li:focus {
      background-color: rgba(255, 255, 255, 0.1); }
    [data-theme="dark"] body .sidenav-nav li a:hover,
    [data-theme="dark"] body .sidenav-nav li a:focus {
      color: #f1b10f; }
    [data-theme="dark"] body .affan-dropdown-menu .dropdown-trigger-btn i {
      color: #f1b10f; }
    [data-theme="dark"] body .sidenav-nav li .night-mode-nav:hover {
      color: #f1b10f; }
    [data-theme="dark"] body .social-info-wrap {
      border-top-color: rgba(255, 255, 255, 0.1); }
    [data-theme="dark"] body .social-info-wrap a {
      border-color: rgba(255, 255, 255, 0.1); }
      [data-theme="dark"] body .social-info-wrap a:hover, [data-theme="dark"] body .social-info-wrap a:focus {
        color: #f1b10f; }
    [data-theme="dark"] body .copyright-info p a {
      color: #ffffff; }
    [data-theme="dark"] body .custom-alert-1 {
      border-color: rgba(255, 255, 255, 0.1); }
    [data-theme="dark"] body .codeview-wrapper {
      background-color: #242644; }
    [data-theme="dark"] body .codeview-btn {
      background-color: #242644;
      color: #ffffff; }
      [data-theme="dark"] body .codeview-btn:hover, [data-theme="dark"] body .codeview-btn:focus {
        background-color: #f1b10f;
        color: #000; }
    [data-theme="dark"] body .header-content .setting-trigger-btn {
      color: #f1b10f; }
    [data-theme="dark"] body .header-content .setting-trigger-btn span {
      background-color: #ea4c62; }
    [data-theme="dark"] body .toast {
      background-color: #242644;
      border-color: transparent; }
    [data-theme="dark"] body .toast-header {
      color: #ffffff;
      background-color: rgba(255, 255, 255, 0.1);
      border-bottom-color: transparent; }
    [data-theme="dark"] body .custom-toast-1 .toast-body .toast-text p {
      color: #ffffff; }
    [data-theme="dark"] body .form-control {
      color: #ffffff;
      background-color: #242644;
/*      border-color: rgba(255, 255, 255, 0.1); */
    }
    [data-theme="dark"] body .form-control.form-control-clicked {
      background-color: #cfe2ff;
/*      border-color: #cfe2ff;*/
      color: #073984; }
    [data-theme="dark"] body .form-control-plaintext {
      color: #ffffff; }
    [data-theme="dark"] body .was-validated .form-control:invalid,
    [data-theme="dark"] body .form-control.is-invalid {
      border-color: rgba(255, 255, 255, 0.1); }
    [data-theme="dark"] body .header-content.header-style-app .navbar--toggler {
      border-color: rgba(255, 255, 255, 0.15); }
    [data-theme="dark"] body .header-content .navbar--toggler span {
      background-color: #ffffff; }
    [data-theme="dark"] body .header-demo-bg {
      background-color: #242644; }
    [data-theme="dark"] body .header-content.header-style-two .search-trigger-btn {
      background-color: rgba(255, 255, 255, 0.1);
      color: #ffffff; }
    [data-theme="dark"] body .header-content.header-style-two .navbar--toggler {
      background-color: rgba(255, 255, 255, 0.1); }
    [data-theme="dark"] body .header-content.header-style-three .navbar--toggler {
      background-color: rgba(255, 255, 255, 0.1); }
    [data-theme="dark"] body .header-content.header-style-six .search-trigger-btn {
      color: #f1b10f; }
    [data-theme="dark"] body .page--item .icon-wrapper {
      background-color: rgba(255, 255, 255, 0.1); }
    [data-theme="dark"] body .page--item.active,
    [data-theme="dark"] body .page--item:hover,
    [data-theme="dark"] body .page--item:focus {
      background-color: rgba(255, 255, 255, 0.1);
      border-color: rgba(255, 255, 255, 0.15); }
    [data-theme="dark"] body .elements-page .page--item.active,
    [data-theme="dark"] body .elements-page .page--item:hover,
    [data-theme="dark"] body .elements-page .page--item:focus {
      background-color: transparent; }
    [data-theme="dark"] body .login-wrapper {
      background-color: #0C153B; }
    [data-theme="dark"] body .login-meta-data a {
      color: #f1b10f; }
    [data-theme="dark"] body .login-back-button a {
      color: #f1b10f; }
    [data-theme="dark"] body .register-form .form-check-label {
      color: rgba(255, 255, 255, 0.7); }
    [data-theme="dark"] body .footer-nav-area {
      background-color: #242644;
      border-top-color: rgba(255, 255, 255, 0.1); }
    [data-theme="dark"] body .footer-nav {
      background-color: #242644; }
    [data-theme="dark"] body .footer-nav ul li a {
      color: rgba(255, 255, 255, 0.5); }
    [data-theme="dark"] body .footer-nav ul li a:hover,
    [data-theme="dark"] body .footer-nav ul li a:focus {
      color: #ffffff; }
    [data-theme="dark"] body .footer-nav ul li a:hover span,
    [data-theme="dark"] body .footer-nav ul li a:focus span {
      color: #f1b10f; }
    [data-theme="dark"] body .footer-nav ul li.active a {
      color: #ffffff; }
    [data-theme="dark"] body .footer-nav ul li.active a span {
      color: #f1b10f; }
    [data-theme="dark"] body .footer-nav.footer-style-three ul li a {
      background-color: rgba(255, 255, 255, 0.1); }
    [data-theme="dark"] body .footer-nav.footer-style-three ul li.active a,
    [data-theme="dark"] body .footer-nav.footer-style-three ul li a:hover,
    [data-theme="dark"] body .footer-nav.footer-style-three ul li a:focus {
      background-color: #f1b10f;
      color: #ffffff; }
    [data-theme="dark"] body .footer-nav.footer-style-five ul li::after {
      background-color: #f1b10f; }
    [data-theme="dark"] body .footer-nav.footer-style-six ul li::after {
      background-color: #f1b10f; }
    [data-theme="dark"] body .setting-popup-card .btn-close,
    [data-theme="dark"] body .custom-alert-1 .btn-close,
    [data-theme="dark"] body .toast .toast-header .btn-close,
    [data-theme="dark"] body .custom-toast-1 .btn-close {
      -webkit-filter: invert(1) grayscale(100%) brightness(200%);
              filter: invert(1) grayscale(100%) brightness(200%); }
    [data-theme="dark"] body .footer-nav.footer-style-two li.active a {
      background-color: rgba(241, 177, 15, 0.3); }
    [data-theme="dark"] body .footer-nav.footer-style-two li.active a::before {
      background-color: #f1b10f; }
    [data-theme="dark"] body .demo-desc strong {
      color: #fff !important; }
    [data-theme="dark"] body .custom-alert-1.alert-dark {
      color: #fff; }
    [data-theme="dark"] body .custom-alert-1.alert-dark::after {
      background-color: #fff; }
    [data-theme="dark"] body .offline-online-card h6 {
      border-bottom-color: rgba(255, 255, 255, 0.1) !important; }
    [data-theme="dark"] body .offline-online-card strong {
      color: #f1b10f !important; }
    [data-theme="dark"] body .form-select {
      color: #ffffff;
      background-color: #242644;
      border-color: rgba(255, 255, 255, 0.1); }
    [data-theme="dark"] body .form-select.form-control-clicked {
      background-color: #cfe2ff;
/*      border-color: #cfe2ff;*/
      color: #073984; }
    [data-theme="dark"] body .input-group-text {
      color: #ffffff;
      background-color: #242644;
      border-color: rgba(255, 255, 255, 0.1); }
    [data-theme="dark"] body .form-check-label {
      color: #ffffff; }
    [data-theme="dark"] body .list-group-item {
      color: #ffffff;
      background-color: #242644;
      border-color: rgba(255, 255, 255, 0.1); }
    [data-theme="dark"] body .single-plan-check {
      border-color: rgba(255, 255, 255, 0.1); }
    [data-theme="dark"] body .single-plan-check.active {
      border-color: #f1b10f; }
    [data-theme="dark"] body .dropdown-menu {
      background-color: #242644; }
      [data-theme="dark"] body .dropdown-menu .dropdown-item {
        color: #ffffff; }
      [data-theme="dark"] body .dropdown-menu .dropdown-item:hover,
      [data-theme="dark"] body .dropdown-menu .dropdown-item:focus {
        color: #ffffff;
        background-color: rgba(255, 255, 255, 0.1); }
    [data-theme="dark"] body #rangevalue {
      color: #ffffff !important;
      border-color: rgba(255, 255, 255, 0.1) !important; }
    [data-theme="dark"] body .autocomplete-items > div {
      background-color: transparent;
      color: #ffffff; }
    [data-theme="dark"] body .autocomplete-items > div strong {
      color: #f1b10f; }
    [data-theme="dark"] body .accordion-button {
      color: #ffffff;
      border-color: rgba(255, 255, 255, 0.1); }
    [data-theme="dark"] body .accordion-button:not(.collapsed) {
      background-color: rgba(255, 255, 255, 0.1); }
    [data-theme="dark"] body .accordion-collapse {
      border-color: rgba(255, 255, 255, 0.1); }
    [data-theme="dark"] body .accordion-item .accordion-body {
      border-color: rgba(255, 255, 255, 0.1) !important; }
    [data-theme="dark"] body .accordion-style-one .accordion-item h6 {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      color: #f1b10f; }
    [data-theme="dark"] body .accordion-style-one .accordion-item h6.collapsed {
      color: #ffffff; }
    [data-theme="dark"] body .accordion-style-two .accordion-item h6.collapsed {
      color: #ffffff; }
    [data-theme="dark"] body .accordion-style-two .accordion-item h6 {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
    [data-theme="dark"] body .accordion-style-three .accordion-item h6 {
      background-color: rgba(255, 255, 255, 0.1);
      border-bottom: 1px solid rgba(255, 255, 255, 0.15);
      color: #ffffff; }
    [data-theme="dark"] body .breadcrumb-wrapper {
      background-color: #242644; }
    [data-theme="dark"] body .breadcrumb {
      background-color: #242644; }
      [data-theme="dark"] body .breadcrumb .breadcrumb-item {
        color: #ffffff; }
        [data-theme="dark"] body .breadcrumb .breadcrumb-item a {
          color: #ffffff; }
          [data-theme="dark"] body .breadcrumb .breadcrumb-item a:hover, [data-theme="dark"] body .breadcrumb .breadcrumb-item a:focus {
            color: #f1b10f; }
    [data-theme="dark"] body .breadcrumb-three .breadcrumb-item a svg {
      color: #f1b10f; }
    [data-theme="dark"] body .card.timeline-card {
      background-color: rgba(255, 255, 255, 0.1) !important; }
    [data-theme="dark"] body .timeline-card.bg-dark {
      border-left-color: #000 !important; }
    [data-theme="dark"] body .image-gallery-card .gallery-menu button.active {
      color: #f1b10f; }
    [data-theme="dark"] body .image-gallery-card .gallery-menu button::after {
      background-color: #f1b10f; }
    [data-theme="dark"] body .rating-card-one > div {
      background-color: transparent; }
    [data-theme="dark"] body .rating-card-two > div {
      border-bottom-color: rgba(255, 255, 255, 0.1) !important; }
    [data-theme="dark"] body .rating-detail .progress-bar-wrapper {
      border-color: rgba(255, 255, 255, 0.1); }
    [data-theme="dark"] body .border-content > span {
      background-color: rgba(255, 255, 255, 0.05) !important; }
    [data-theme="dark"] body .img-thumbnail {
      border-color: rgba(255, 255, 255, 0.15);
      background-color: transparent; }
    [data-theme="dark"] body .list-group-item.active {
      border-color: rgba(255, 255, 255, 0.1) !important;
      color: #f1b10f; }
    [data-theme="dark"] body .list-group-item.disabled {
      border-color: rgba(255, 255, 255, 0.1) !important;
      color: rgba(241, 177, 15, 0.4); }
    [data-theme="dark"] body .modal-content {
      background-color: #242644; }
      [data-theme="dark"] body .modal-content .btn-close {
        -webkit-filter: invert(1) grayscale(100%) brightness(200%);
                filter: invert(1) grayscale(100%) brightness(200%); }
      [data-theme="dark"] body .modal-content .modal-header {
        border-bottom-color: rgba(255, 255, 255, 0.1); }
      [data-theme="dark"] body .modal-content .modal-footer {
        border-top-color: rgba(255, 255, 255, 0.1); }
    [data-theme="dark"] body .page-link {
      color: #ffffff;
      background-color: transparent;
      border-color: rgba(255, 255, 255, 0.05); }
    [data-theme="dark"] body .page-item.active .page-link {
      color: #ffffff;
      background-color: #ea4c62;
      border-color: #ea4c62; }
    [data-theme="dark"] body .page-item.disabled .page-link {
      color: rgba(241, 177, 15, 0.4);
      background-color: rgba(255, 255, 255, 0.05);
      border-color: rgba(255, 255, 255, 0.05); }
    [data-theme="dark"] body .pagination.pagination-one .page-item:first-child .page-link {
      border-left: 1px solid rgba(255, 255, 255, 0.05); }
    [data-theme="dark"] body .pagination.pagination-one .page-link {
      border-color: rgba(255, 255, 255, 0.05); }
    [data-theme="dark"] body .pagination.pagination-one .page-item:last-child .page-link {
      border-color: rgba(255, 255, 255, 0.05); }
    [data-theme="dark"] body .pagination.pagination-one .page-item.active .page-link::after {
      background-color: #ea4c62; }
    [data-theme="dark"] body .progress {
      background-color: rgba(255, 255, 255, 0.1); }
    [data-theme="dark"] body .skill-progress-bar .skill-icon {
      border-color: rgba(255, 255, 255, 0.1); }
      [data-theme="dark"] body .skill-progress-bar .skill-icon svg {
        color: #ffffff !important; }
    [data-theme="dark"] body .skill-progress-bar .skill-name p {
      color: #ffffff; }
    [data-theme="dark"] body .scrollspy-indicatiors .nav-link {
      background-color: rgba(255, 255, 255, 0.1);
      color: #ffffff; }
    [data-theme="dark"] body .scrollspy-indicatiors .nav-link.active {
      background-color: #f1b10f;
      color: #ffffff; }
    [data-theme="dark"] body .table {
      color: rgba(255, 255, 255, 0.7);
      border-color: rgba(255, 255, 255, 0.2); }
    [data-theme="dark"] body .data-table tbody tr.even > td {
      background-color: rgba(255, 255, 255, 0.1); }
    [data-theme="dark"] body .data-table tbody tr > td {
      border-bottom-color: transparent; }
    [data-theme="dark"] body div.dataTables_wrapper div.dataTables_paginate .paginate_button {
      background-color: rgba(255, 255, 255, 0.1);
      color: #ffffff; }
    [data-theme="dark"] body div.dataTables_wrapper div.dataTables_paginate span .paginate_button.current {
      color: #ffffff;
      background-color: #ea4c62; }
    [data-theme="dark"] body .price-table-two .single-price-table {
      background-color: rgba(255, 255, 255, 0.05); }
    [data-theme="dark"] body .price-table-two .single-price-table.active {
      background-color: #232F3E; }
    [data-theme="dark"] body .countdown-wrapper {
      color: #ffffff; }
    [data-theme="dark"] body .single-product-card .product-title {
      color: #ffffff; }
    [data-theme="dark"] body .single-product-card .sale-price {
      color: #f1b10f; }
    [data-theme="dark"] body .cart-table .qty-text {
      border-color: rgba(255, 255, 255, 0.1);
      background-color: transparent;
      color: #ffffff; }
    [data-theme="dark"] body .cart-table table .remove-product {
      color: #ffffff;
      background-color: rgba(255, 255, 255, 0.1); }
    [data-theme="dark"] body .cart-table table .remove-product:hover,
    [data-theme="dark"] body .cart-table table .remove-product:focus {
      color: #ffffff;
      background-color: #ea4c62; }
    [data-theme="dark"] body .team-member-card {
      background-color: rgba(255, 255, 255, 0.1) !important; }
    [data-theme="dark"] body .table-light {
      color: #ffffff;
      border-color: rgba(255, 255, 255, 0.15); }
      [data-theme="dark"] body .table-light tr th,
      [data-theme="dark"] body .table-light tr td {
        background-color: transparent;
        border-color: rgba(255, 255, 255, 0.15); }
    [data-theme="dark"] body .language-lists li {
      border-color: rgba(255, 255, 255, 0.1); }
    [data-theme="dark"] body .notification-area .alert {
      background-color: rgba(255, 255, 255, 0.1);
      border-color: rgba(255, 255, 255, 0.1);
      color: #ffffff; }
      [data-theme="dark"] body .notification-area .alert.unread {
        background-color: rgba(255, 255, 255, 0.2) !important;
        border-color: rgba(255, 255, 255, 0.2);
        color: #ffffff; }
    [data-theme="dark"] body .single-search-result {
      border-bottom-color: rgba(255, 255, 255, 0.1) !important; }
      [data-theme="dark"] body .single-search-result a {
        color: rgba(241, 177, 15, 0.5); }
    [data-theme="dark"] body .blog-title {
      color: #ffffff !important; }
    [data-theme="dark"] body .blog-title:hover,
    [data-theme="dark"] body .blog-title:focus {
      color: #f1b10f !important; }
    [data-theme="dark"] body .search-form-wrapper form {
      border-bottom-color: rgba(255, 255, 255, 0.1); }
    [data-theme="dark"] body .single-setting-panel a:hover, [data-theme="dark"] body .single-setting-panel a:focus {
      color: #f1b10f; }
    [data-theme="dark"] body .preview-iframe-wrapper .preview-hero-area .demo-title span {
      color: #f1b10f; }
    [data-theme="dark"] body .table-striped > tbody > tr:nth-of-type(2n+1) {
      color: rgba(255, 255, 255, 0.7); }
