 body { padding-right: 0 !important }
.py-3 {
padding-top: 0rem!important; 
padding-bottom: 0rem!important;
}
.form-check label{
  cursor: pointer;
}
 

 .form-control {
  -webkit-transition-duration: 500ms;
          transition-duration: 500ms;
          border-radius: 0;
  border-style: solid;
    padding: 0.375rem 1rem;
    height:50px;
  font-size: 14px;
  color: #000;
  background-color: #ffffff;
  border-width: 1px;
}


  .form-control.form-control-clicked {
    background-color: #cfe2ff;
    color: #000; }
  .form-control:focus {
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
    color: #000;
    background-color: #ffffff; }
  .form-control.form-control-lg {
    height: 50px;
    padding: 0.625rem 1rem;
    font-size: 16px; }
  .form-control.form-control-sm {
    height: 32px;
    padding: 0.375rem 0.5rem;
    font-size: 12px;
    border-radius: 0.375rem; }

    .table {
  font-size: 14px; }

  textarea.form-control{

    height: 150px;
  }

.select__control {
     min-height: 50px!important;
}

.select__menu-list{
  height: 200px!important;
  overflow-y: scroll!important;
  width: 100%;
}

.codekali-loader-spin {
position: fixed;
 top: 0;
  left: 0;
  right: 0;
  bottom: 0;
width: 100%;
height: 100%;
z-index: 99999;
  background-color: #fff;
}
 
.codekali-loader-spin-img{
position: absolute;
top: 50%;
left: 50%;
-ms-transform: translateX(-50%) translateY(-50%);
-webkit-transform: translate(-50%,-50%);
transform: translate(-50%,-50%);
}
.codekali-popup-card{
height: 200px;
border-radius: 30px;
}
.client-img{
height: 150px;
object-fit: contain;
}
.codekali-bold{
font-weight: bold;
}
a{
text-decoration: none!important;
}
.codekali-round{
border-radius: 30px;
}
#rocket {
/*margin: 50px 0px 0px 45%;*/
width: 100px;
text-align: center;
}
.codekali-logo-floater{
width: 207.9px;
height: 64.45px;
}
div#codekali_wrapper {
width: 100%;
min-height: 1500px; 
/*height: 500px;*/
margin: 0px auto;
background-color: #000;
background-image: url(https://codekali.com/images/earth.webp), url(https://codekali.com/images/stars.webp), url(https://codekali.com/images/stars-color.webp);
background-size: 1000px, 200px, 150px;
background-position: 400% 40px, 0px 0px, 0px 0px;
background-repeat: no-repeat, repeat, repeat;
animation: animate-background linear 60s infinite;    
}
@keyframes animate-background {
from {
background-position: 400% 70px, 0px 0px, 0px, 0px;
}
to {
background-position: -300% 70px, -1500px 0px, -1500px 0px;
}
}
/*preloader*/
#codekalipreloader {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
z-index: 99999;
}
#codekaliloader-img {
display: block;
position: relative;
left: 50%;
top: 50%;
width: 200px;
height: 200px;
margin: -100px 0 0 -100px;
z-index: 1001;  
background-image: url('https://codekali.com/images/codekali_icon.png');
background-repeat: no-repeat;
background-size: 50%;
background-position: center;
z-index: 1020;
}
#codekaliloader {
display: block;
position: relative;
left: 50%;
width: 200px;
height: 200px;
margin: -100px 0 0 -100px;
border-radius: 50%;
border: 3px solid transparent;
border-top-color: #ffa200;
-webkit-animation: spin 2s linear infinite;
animation: spin 2s linear infinite;
z-index: 1001;
}
#codekaliloader:before {
content: "";
position: absolute;
top: 5px;
left: 5px;
right: 5px;
bottom: 5px;
border-radius: 50%;
border: 3px solid transparent;
border-top-color: #0BF9FF;
-webkit-animation: spin 3s linear infinite;
animation: spin 3s linear infinite;
}
#codekaliloader:after {
content: "";
position: absolute;
top: 15px;
left: 15px;
right: 15px;
bottom: 15px;
border-radius: 50%;
border: 3px solid transparent;
border-top-color: #01515F;
-webkit-animation: spin 1.5s linear infinite;
animation: spin 1.5s linear infinite;
}
@-webkit-keyframes spin {
0%   {
-webkit-transform: rotate(0deg);
-ms-transform: rotate(0deg);
transform: rotate(0deg);
}
100% {
-webkit-transform: rotate(360deg);
-ms-transform: rotate(360deg);
transform: rotate(360deg);
}
}
@keyframes spin {
0%   {
-webkit-transform: rotate(0deg);
-ms-transform: rotate(0deg);
transform: rotate(0deg);
}
100% {
-webkit-transform: rotate(360deg);
-ms-transform: rotate(360deg);
transform: rotate(360deg);
}
}
.codekaliloader-section {
position: fixed;
top: 0;
width: 51%;
height: 100%;  
background: #222;
z-index: 1000;
-webkit-transform: translateX(0);
-ms-transform: translateX(0);
transform: translateX(0)
}
.codekaliloader-section.codekalisection-left {
left: 0
}
.codekaliloader-section.codekalisection-right {
right: 0
}
.codekalipanel_left {
-webkit-transform: translateX(-100%);
-ms-transform: translateX(-100%);
transform: translateX(-100%);
-webkit-transition: all .7s .3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
transition: all .7s .3s cubic-bezier(0.645, 0.045, 0.355, 1.000)
}
.codekalipanel_right {
-webkit-transform: translateX(100%);
-ms-transform: translateX(100%);
transform: translateX(100%);
-webkit-transition: all .7s .3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
transition: all .7s .3s cubic-bezier(0.645, 0.045, 0.355, 1.000)
}
.codekaliloaded-circle {
opacity: 0;
-webkit-transition: all .3s ease-out;
transition: all .3s ease-out
}
.codekaliloaded-img {
visibility: hidden;
-webkit-transform: translateY(-100%);
-ms-transform: translateY(-100%);
transform: translateY(-100%);
-webkit-transition: all .3s 1s ease-out;
transition: all .3s 1s ease-out
}
.codekalicore-toggle-profile{
vertical-align: text-bottom!important;
}
.codekalicore-flex{
justify-content: space-between;
display: flex;
}


.codekali-typing{

font-size: 2em;
}

 .header-nav-icon{

                 width: 127.5px!important;
        height: 67px!important;
 }



 .header-nav-logo{
           width: 200px!important;
        height: 75px!important;
 }
 

/*mobile*/

@media screen and (max-width: 748px){


  .codekali-header-logo{
    text-align: center;
  }
.codekali-typing{

font-size: 1.2em;
}

  .codekali-title{

font-size: 1em;
  }

    .btn{

font-size: 1em;
  }

  .mobile-font{

font-size: 12px;
  }

.nav-tabs .nav-item button{
font-size: 12px;
}

.mobile-top-ad{
display: block;
}

.desktop-top-ad{
display: none;
}

#codekali_cat_menu{

display: none;
}

.mobile-text-hide{

display: none;
}

.codekali-footer-icon{

font-size: 18px!important;
}
}

@media only screen and (max-width: 768px) {
   
div#codekali_wrapper {
width: 100%;
/*min-height: 6600px;*/
min-height: 1500px; 

}

.header-nav-logo {
    width: 160px !important;
    height: 60px !important;
}
}